import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box } from 'rebass';
import { DocumentCard } from '../../BbshDocument/Card';

const MemberCategoryPageLoadingState = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        width={320}
        height={40}
        viewBox="0 0 320 40"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="4" ry="4" width="128" height="40" />
        <rect x="148" y="0" rx="4" ry="4" width="128" height="40" />
      </ContentLoader>
      {[...Array(2).keys()].map((_, idx) => (
        <>
          <Box mt={3}>
            <ContentLoader
              speed={2}
              width={256}
              height={24}
              viewBox="0 0 256 24"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="4" ry="4" width="256" height="24" />
            </ContentLoader>
          </Box>
          <DocumentCard p={[3, null, 4]} key={`loader-${idx}`} my={4}>
            <ContentLoader
              speed={2}
              width={320}
              height={72}
              viewBox="0 0 320 72"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect rx="4" ry="4" width="256" height="24" />
              <rect y="32" rx="4" ry="4" width="320" height="36" />
            </ContentLoader>
          </DocumentCard>
        </>
      ))}
    </>
  );
};

export default MemberCategoryPageLoadingState;
