import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../../Text';
import { Flex, Box } from 'rebass';
import BbshDocument from '../../../BbshDocument/Card';
import { Location } from '@reach/router';

const MemberCategorySection = ({ section, location }) => {
  const sectionTitle = section?.primary?.section_title?.[0]?.text;
  const sectionIcon = section?.primary?.section_icon?.url;
  return (
    <>
      <Flex alignItems="center" mb={3}>
        {sectionIcon && <img src={sectionIcon} />}
        <Text
          ml={sectionIcon ? 1 : undefined}
          variant="tertiary"
          fontSize={[3, 4]}
          key={sectionTitle}
        >
          {sectionTitle}
        </Text>
      </Flex>
      <Flex flexWrap="wrap" m={-3}>
        {section.fields.map((file, idx, arr) => {
          const fileTitle = file?.document_title?.[0]?.text;
          return (
            <Box
              key={fileTitle}
              width={arr.length > 1 ? [1, 1 / 2, 1 / 3] : 1}
              p={3}
            >
              <BbshDocument
                goBack={encodeURIComponent(
                  `${location.pathname}${location.search}`,
                )}
                {...file?.bbsh_document}
              />
            </Box>
          );
        })}
      </Flex>
    </>
  );
};

MemberCategorySection.propTypes = {
  tabTitle: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
};

const MemberCategorySectionWithLocation = props => (
  <Location>
    {location => <MemberCategorySection {...props} {...location} />}
  </Location>
);

MemberCategorySectionWithLocation.displayName =
  'MemberCategorySectionWithLocation';

export default MemberCategorySectionWithLocation;
