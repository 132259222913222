import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MemberCategoryBadge from './Badge';
import MemberCategorySection from './Section';
import Text from '../../../Text';
import Container from '../../../Container';
import styled from 'styled-components';

const TabsWrapper = styled(Box)`
  background: ${({ theme }) => theme.grays['200']};
`;

const MemberCategoryTabs = ({ edges, selectedIndex, onChangeTab }) => {
  return (
    <Tabs onSelect={onChangeTab} selectedIndex={selectedIndex}>
      <TabList>
        <TabsWrapper flexWrap={['nowrap', null, 'wrap']} mb={4}>
          <Container>
            <Flex mx={-2} pt={[0, null, 3]} style={{ overflowX: 'auto' }}>
              {edges?.map(({ node }, idx) => {
                const isActive =
                  (!selectedIndex && idx === 0) || selectedIndex === idx;
                return (
                  <Tab key={`tab-${node?._meta.id}`}>
                    <Box px={2}>
                      <MemberCategoryBadge node={node} isActive={isActive}>
                        <img src={node?.icon?.url} />
                        <Text
                          ml={1}
                          variant={isActive ? undefined : 'secondary'}
                        >
                          {node?.display_name[0].text}
                        </Text>
                      </MemberCategoryBadge>
                    </Box>
                  </Tab>
                );
              })}
            </Flex>
          </Container>
        </TabsWrapper>
      </TabList>
      <Container>
        {edges?.map(({ node }) => {
          const tabTitle = node?.display_name[0].text;
          return (
            <TabPanel key={`content-${node?._meta.id}`}>
              <Flex flexDirection="column" m={-3}>
                {node?.body?.map((section, idx) => {
                  return (
                    <Box p={3} key={`section-${idx}`}>
                      <MemberCategorySection
                        tabTitle={tabTitle}
                        section={section}
                      />
                    </Box>
                  );
                })}
              </Flex>
            </TabPanel>
          );
        })}
      </Container>
    </Tabs>
  );
};

MemberCategoryTabs.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.object),
};

export default MemberCategoryTabs;
