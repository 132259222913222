import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from '../../../firebase/config';
import { useFirebaseAuth } from '../../../components/FirebaseAuth';

const useMembershipLevels = () => {
  const { user, loading: userLoading } = useFirebaseAuth();
  const [data, dataLoading, dataError] = useCollection(
    user
      ? firebase
          ?.firestore()
          .collection('membershipLevels')
          .orderBy('label')
      : undefined,
  );
  const isLoading = userLoading || dataLoading;
  const labels = data?.docs?.map(doc => doc.data().label);
  return {
    data,
    labels,
    isLoading,
    loading: isLoading,
    error: dataError,
  };
};

export default useMembershipLevels;
