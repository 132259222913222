import React, { useEffect } from 'react';
import qs from 'qs';
import MembersLayout from '../../../components/Members/Layout';
import Container from '../../../components/Container';
import { navigate } from 'gatsby';
import MemberCategoryTabs from '../../../components/Members/Category/Tabs';
import { Flex, Box } from 'rebass';
import Text from '../../../components/Text';
import MemberCategoryPageLoadingState from '../../../components/Members/Category/PageLoadingState';
import styled from 'styled-components';
import useBbshMember from '../../../utils/hooks/members/useBbshMember';
import usePrismic from '../../../utils/hooks/members/usePrismic';
import { getTabsContent } from '../../../components/Members/queries';
import useMembershipLevels from '../../../utils/hooks/members/useMembershipLevels';
import GateKeeper from '../../../components/FirebaseAuth/GateKeeper';
import SEO from '../../../components/seo';

const CategoryHeader = styled(Box).attrs({
  py: [4, null, 5],
})`
  background: linear-gradient(175.2deg, #66326f 0%, #2b1953 100%);
  color: white;
`;

const sortTabs = edges => {
  return edges.sort((a, b) => {
    return a.node.display_name[0].text > b.node.display_name[0].text;
  });
};

const getDefaultTabFromMemberYear = ({ member, edges, membershipLevels }) => {
  if (member?.membershipLevel && edges) {
    const { membershipLevel: id } = member;
    const membershipLevel = membershipLevels?.docs
      ?.find(doc => doc.id === id)
      .data().label;
    const memberYear = membershipLevel?.match(/Year \d+/)?.[0];
    const tabFromYearIndex = edges.findIndex(({ node }) =>
      node.display_name[0].text.includes(memberYear),
    );
    if (tabFromYearIndex >= 0) return tabFromYearIndex;
  }
};

const MemberCategory = ({ location }) => {
  const { member } = useBbshMember();
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { categoryId, tabIndex } = query;
  const [fetch, { data, isLoading }] = usePrismic({
    query: getTabsContent,
    variables: { where: { category: categoryId } },
  });
  useEffect(() => {
    fetch();
  }, []);
  const categoryTitle =
    data?.allTabs?.edges?.[0]?.node?.category?.title[0].text;
  const categoryDescription =
    data?.allTabs?.edges?.[0]?.node?.category?.description;
  const categoryIcon = data?.allTabs?.edges?.[0]?.node?.category?.icon?.url;
  const onChangeTab = index => {
    const newQuery = {
      ...query,
      tabIndex: index,
    };
    const newSearch = qs.stringify(newQuery);
    navigate(`${location.pathname}?${newSearch}`);
  };
  const sortedEdges = sortTabs(data?.allTabs?.edges || []);
  const { data: membershipLevels } = useMembershipLevels();
  const defaultTab =
    tabIndex ||
    getDefaultTabFromMemberYear({
      member,
      edges: sortedEdges,
      membershipLevels,
    });
  return (
    <GateKeeper>
      <SEO title={categoryTitle} />
      <MembersLayout
        goBackLink="/members"
        isLoading={isLoading}
        title={
          <Flex alignItems="center">
            <img src={categoryIcon} /> <Text ml={1}>{categoryTitle}</Text>
          </Flex>
        }
      >
        {categoryDescription && (
          <CategoryHeader>
            <Container>{categoryDescription}</Container>
          </CategoryHeader>
        )}
        {isLoading ? (
          <Container py={[3, 4]}>
            <MemberCategoryPageLoadingState />
          </Container>
        ) : (
          <MemberCategoryTabs
            onChangeTab={onChangeTab}
            selectedIndex={Number(defaultTab) || 0}
            edges={data?.allTabs?.edges}
          />
        )}
      </MembersLayout>
    </GateKeeper>
  );
};

MemberCategory.propTypes = {};

export default MemberCategory;
