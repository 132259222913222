import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const StyledBadge = styled(Box).attrs({ p: 2 })`
  ${({ isActive, theme }) => css`
    cursor: pointer;
    transition: 0.1s;
    color: inherit;
    /* background: ${isActive ? theme.colors.valhalla : 'white'}; */
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: ${isActive ? theme.colors.trendyPink : 'transparent'};
    display: flex;
    align-items: center;
    white-space: nowrap;
    & > * {
      text-overflow: ellipsis;
    }
    img {
      filter: ${isActive ? 'none' : 'invert(1)'};
    }
    /* &:hover {
      background: ${!isActive ? theme.grays['200'] : theme.colors.valhalla};
    } */
  `}
`;

const MemberCategoryBadge = ({ isActive, children }) => {
  return <StyledBadge isActive={isActive}>{children}</StyledBadge>;
};

MemberCategoryBadge.propTypes = {
  node: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default MemberCategoryBadge;
